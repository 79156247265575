<template>
  <span>
    <img :src="src" :width="width" :height="height"  />
  </span>
</template>

<script>
export default {
  props: {
    name: String,
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
  },
  computed: {
    src() { 
       return require(`../../../public/img/${this.name}.svg`);
    }
  }
};
</script>