import Vue from 'vue'
import Router from 'vue-router'
// import * as firebase from "firebase";
//import { SSL_OP_NETSCAPE_CA_DN_BUG } from 'constants'

import store from '../util/store.js';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const MarketReport = () => import('@/views/reports/MarketReport') 
const MarketReportResult = () => import('@/views/reports/MarketReportResult') 

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
//const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/account/Login')
const TryFree = () => import('@/views/account/TryFree')

const Register = () => import('@/views/account/Register')
const ReSendMail = () => import('@/views/account/ReSendMail')

const MyAccount = () => import('@/views/account/MyAccount')
const MyProfile = () => import('@/views/account/MyProfile')
const MyReports = () => import('@/views/reports/MyReports')
const ShowReport = () => import('@/views/reports/ShowReport')

const Voucher = () => import('@/views/voucher/Voucher')

const Survey = () => import('@/views/survey/SurveyV2Register')

const Feedback = () => import('@/views/feedback/Feedback')
const Learn = () => import('@/views/learn/Learn')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

router.beforeEach((to, from, next) => {
  //const currentUser = firebase.auth().currentUser;
  const currentUser = store.state.userName;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if(requiresAuth && !currentUser){
    next('/login');
  }
  else if(requiresAuth && currentUser){
    next();
  }
  else{
    next();
  }
});

export default router;

function configRoutes () {
    return [
    {
        path: '/tryFree',
        name: 'tryFree',
        component: TryFree
    },
    {
        path: '/login', 
        name: 'Login',
        component: Login
        //component: GoogleLogin,
        //component: {

            //render(c) { return c('router-view') }
        //},
        //children: [
        //    {
        //        path: 'login',
        //        name: 'Login',
        //        component: Login
        //    },
        //    {
        //        path: 'register',
        //        name: 'Register',
        //        component: Register
        //    }
        //]
    },
    {
        path: '/register',
        component: Register
    },
    {
        path: '/resendmail',
        component: ReSendMail
    }, 
    {
      path: '/',
      redirect: '/marketReport',
      name: 'Home',
      component: TheContainer,
      meta:{
        requiresAuth: true
      },
      children: [
        {
          path: '/marketReport',
          name: 'MarketReport',
          component: MarketReport
        }, 
        {
          path: '/marketReportResult',
          name: 'MarketReportResult',
          component: MarketReportResult
        }, 
        {
          path: '/myAccount',
          name: 'MyAccount',
          component: MyAccount
        },
        {
          path: '/Voucher',
          name: 'Voucher',
          component: Voucher
        },  
        {
          path: '/MyProfile',
          name: 'MyProfile',
          component: MyProfile
        },  
        {
          path: '/Learn',
          name: 'Learn',
          component: Learn
        }, 
        {
          path: '/MyReportList',
          name: 'MyReportList',
          component: MyReports
        },
        {
          path: '/ShowReport',
          name: 'ShowReport',
          component: ShowReport,
          props: true
        },
        {
          path: '/feedback',
          name: 'feedback',
          component: Feedback,
          props: true
        },
        {
          path: '/survey',
          name: 'Survey',
          component: Survey
        },
      ]
    },
    {
      path: '*',
      component: Page404,
    },    
  ]
}